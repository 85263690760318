<template>
    <div id="page-matrixx-subscription-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'matrixx-subscriptions' }">Back to Subscriptions</router-link>

                    <div class="header-info">
                        <h1 class="heading">Subscription Details</h1>
                    </div>
                </div>
            </div>

            <div v-if="details" class="row details">
                <div class="col-mob-12"
                    :class="on_sidebar ? 'col-12' : 'col-6'"
                >
                    <app-table-details
                        :rows="rows.group"
                        :data="details"
                        title="Details"
                    />

                </div>
            </div>
            <div class="row details">
                <div v-if="subscription_uuid" class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <devices-table :current-page="1" title="Devices" :filter-uuid="subscription_uuid" @row-click="openDeviceDetails" @row-click-mobile="openDeviceDetails" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import devicesTable from '@/views/matrixx/devices/devices-table'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
        devicesTable
    },

    data() {
        return {
            details: null,

            rows: {
                group: [
                    { key: 'Name', title: 'Name' },
                    { key: 'CreatedAtNanos', title: 'Date Created', type: 'datenanos' },
                    { key: 'UpdatedAtNanos', title: 'Last Updated', type: 'datenanos' },
                ],
            },

            loading: false,

            on_sidebar: false,
            from_customer: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
        this.from_customer = this.$route.name === 'matrixx-customer-group-details'
    },

    methods: {
        init() {
            this.loadDetails()
        },
        
        loadDetails () {
            if (this.uuid) {
                this.loading = true
                this.$store.dispatch('api_matrixx/GetMtxSubscriptionByUUID', { UUID: this.subscription_uuid, SPID: this.current_spid }).then(resp => {
                    this.details = resp
                    this.loading = false
                }).catch(error => {
                    console.error(error)
                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({ name: 'matrixx-sp-groups', replace: true })
                    }
                })
            }
        },

        openDeviceDetails(row) {
            if (this.from_customer) {
                this.$router.push({ name: 'matrixx-customer-group-device-details', params: { uuid: row.UUID, customer_uuid: this.$route.params.uuid } })
            } else {
                this.$router.push({ name: 'matrixx-device-details', params: { UUID: row.UUID} })
            }
        },
    },

    watch: {
        subscription_uuid(val) {
            if (val) {
                this.loadDetails()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid'
        ]),

        subscription_uuid() {
            if (this.$route.name === 'matrixx-subscription-details') {
                return this.$route.params.uuid
            }
            return this.uuid
        },
    },
}
</script>

<style lang="scss">
#page-matrixx-subscription-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .details {
        margin-bottom: 30px;

        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:last-child { margin: 0 };
    }

    .devices-table {
        @include table-cols-width((150px, 150px, 200px, 120px, 85px), true);
    }
}

@media (max-width: $tablet-size) {
    #page-matrixx-subscription-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }
    }

.devices-table {
    @include table-cols-width((150px, 150px, 150px, 100px, 85px), true);
}
}

@media (max-width: $mobile-size) {
    #page-matrixx-subscription-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .details {
            margin-bottom: 15px;

            .col-6,
            .col-12,
            .col-mob-12 {
                margin-bottom: 15px;
            }
        }

        .devices-table {
            margin-bottom: 15px;
            @include table-cols-width-mobile((150px, 150px), true);
        }
    }
}
</style>
